import BackendApi from '@/services/backend.service'

export default [
  //WHATSAPP BUSINESS
  {
    path: '/whatsapp/config/account',
    name: 'whatsapp-account',
    component: () => import('@/pages/Services/whatsapp/AccountTab.vue')
  }
]
