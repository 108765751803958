import Vue from 'vue'
import App from './App.vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import store from './store'
import router from './router'

// PLUGINS and other imports...
import vuetify from './plugins/vuetify'
import i18n from './plugins/vue-i18n'
import './plugins/vue-google-maps'
import './plugins/vue-shortkey'
import './plugins/vue-head'
import './plugins/vue-gtag'
import './plugins/apexcharts'
import './plugins/echarts'
import './plugins/animate'
import './plugins/clipboard'
import './plugins/moment'
import './plugins/vue-tree'
import './plugins/json-excel'
import './plugins/backend-interceptor'
import './plugins/vue-debounce'
import './plugins/vue-datetime'
import './plugins/vue-clipboard'
import './plugins/cookies'

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/formatCurrency'
import './filters/formatDate'

// STYLES
import './assets/scss/theme.scss'
import 'animate.css/animate.min.css'

import MPEGMode from 'lamejs/src/js/MPEGMode'
import Lame from 'lamejs/src/js/Lame'
import BitStream from 'lamejs/src/js/BitStream'

let sentryUser = {
  id: -1,
  name: 'Usuario no identificado',
  email: 'Usuario no identificado'
}

const userCookie = $cookies?.get('user')

if (userCookie) {
  sentryUser = {
    id: userCookie.id ?? null,
    name: userCookie.name ?? null,
    email: userCookie.email ?? null
  }
}

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DNS,
  initialScope: {
    tags: sentryUser,
    user: sentryUser
  },
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  tracesSampleRate: 1.0, // Adjust this as needed
  replaysSessionSampleRate: 0.1, // Adjust this as needed
  replaysOnErrorSampleRate: 1.0, // Adjust this as needed
  tracePropagationTargets: ['localhost', 'https://app.enviamas.pe', 'https://test-app.enviamas.pe/']
})

window.MPEGMode = MPEGMode
window.Lame = Lame
window.BitStream = BitStream

Vue.config.productionTip = false

new Vue({
  i18n,
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
