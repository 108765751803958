<template>
  <div>
    <v-btn
      ref="button"
      class="drawer-button"
      color="#e75504"
      dark
      @click="right = true"
    >
      <v-icon class="fa-spin">mdi-cog-outline</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="right"
      fixed
      right
      hide-overlay
      temporary
      width="310"
    >
      <div class="d-flex align-center pa-2">
        <div class="title">Configuraciones</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="right = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <div class="pa-2">
        <div v-if="isUsersRoute" class="users-toggle-conatiner">
          <div class="font-weight-bold my-1">Cambiar vista</div>
          <v-btn-toggle v-model="users_tree_view" color="primary" mandatory class="mb-2">

            <v-btn x-large>Tabla</v-btn>
            <v-btn x-large>Árbol</v-btn>

          </v-btn-toggle>

          <div class="font-weight-bold my-1">Ver límite de crédito y crédito disponible</div>
          <v-btn-toggle v-model="users_credits" color="primary" mandatory class="mb-2">

            <v-btn x-large>Sí</v-btn>
            <v-btn x-large>No</v-btn>

          </v-btn-toggle>
        </div>
        <div v-if="isFilesRoute" class="files-toggle-container">
          <div class="font-weight-bold my-1">Ver espacio utilizado</div>
          <v-btn-toggle v-model="show_file_graph" color="primary" mandatory class="mb-2">

            <v-btn x-large>No</v-btn>
            <v-btn x-large>Sí</v-btn>

          </v-btn-toggle>

          <br>
          <v-btn color="primary" @click="uploadFileActiveInFilesView()">Subir archivo</v-btn>
          <br>
          <br>
          <v-btn
            outlined
            color="primary"
            small
            @click="reload()"
          >
            <v-icon>
              mdi-reload
            </v-icon>
          </v-btn>
        </div>

        <v-divider></v-divider>

      </div></v-navigation-drawer>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      right: false,
      theme: 0,
      toolbarTheme: 0,
      toolbarStyle: 0,
      contentBoxed: 0,
      menuTheme: 0,
      color: '#1e1e35',
      swatches: [
        ['#1e1e35', '#31944f'],
        ['#EE4f12', '#46BBB1'],
        ['#ee44aa', '#55BB46']
      ],

      rtl: 0,

      // timezones
      availableTimezones: ['America/Los_Angeles', 'America/New_York', 'Europe/London', 'Asia/Tokyo', 'Australia/Sydney'],

      // time formats
      availableFormats: [{
        label: '07/31/2020',
        format: 'L'
      }, {
        label: 'Jul 31, 2020',
        format: 'll'
      }, {
        label: '20200731',
        format: 'YYYYMMDD'
      }],
      users_tree_view: false,
      users_credits: true,
      show_file_graph: 1
    }
  },
  computed: {
    ...mapState('app', ['time', 'currency', 'availableCurrencies']),
    isUsersRoute() {
      return this.$route.path === '/config/users'
    },
    isFilesRoute() {
      return this.$route.path === '/tools/filescloud'
    }
  },
  watch: {
    color(val) {
      const { isDark } = this.$vuetify.theme

      this.$vuetify.theme.themes.dark.primary = val
      this.$vuetify.theme.themes.light.primary = val
    },
    theme(val) {
      this.setGlobalTheme((val === 0 ? 'light' : 'dark'))
    },
    toolbarTheme(val) {
      const theme = val === 0 ? 'global' : (val === 1 ? 'light' : 'dark')

      this.setToolbarTheme(theme)
    },
    toolbarStyle(val) {
      this.setToolbarDetached(val === 1)
    },
    menuTheme(val) {
      const theme = val === 0 ? 'global' : (val === 1 ? 'light' : 'dark')

      this.setMenuTheme(theme)
    },
    contentBoxed(val) {
      this.setContentBoxed(val === 1)
    },
    rtl(val) {
      this.setRTL(val)
    },
    //
    users_tree_view(newValue, oldValue) {
      if (newValue) {
        this.$emit('change-users-tree-view-visibility', newValue) 
      } else {
        this.$emit('change-users-tree-view-visibility', newValue) 
      }
    },
    users_credits(newValue, oldValue) {
      if (newValue) {
        this.$emit('change-users-credits-visibility', newValue) 
      } else {
        this.$emit('change-users-credits-visibility', newValue) 
      }
    },
    show_file_graph(newValue, oldValue) {
      
      this.$emit('file-graph-visibility', newValue)  
    }
  },
  mounted() {
    this.animate()
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout)
  },
  methods: {
    ...mapMutations('app', ['setMenuTheme', 'setGlobalTheme', 'setToolbarTheme', 'setContentBoxed', 'setTimeZone', 'setTimeFormat', 'setCurrency', 'setRTL', 'setToolbarDetached']),
    setTheme() {
      this.$vuetify.theme.dark = this.theme === 'dark'
    },
    animate() {
      if (this.timeout) clearTimeout(this.timeout)

      const time = (Math.floor(Math.random() * 10 + 1) + 10) * 1000

      this.timeout = setTimeout(() => {
        this.$animate(this.$refs.button.$el, 'bounce')
        this.animate()
      }, time)
    },
    uploadFileActiveInFilesView() {
      this.$emit('upload-file-active')
    },
    reload() {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-button {
  position: fixed;
  top: 340px;
  right: -20px;
  z-index: 6;
  box-shadow: 1px 1px 18px #ff7f0f;

  .v-icon {
    margin-left: -18px;
    font-size: 1.3rem;
  }

}
</style>
