import menuUI from './menus/ui.menu'
import menuApps from './menus/apps.menu'
import menuPages from './menus/pages.menu'

export default {
  // main navigation - side menu
  menu: [
    {
      text: '',
      key: '',
      items: [
        {
          icon: 'mdi-view-dashboard-outline',
          text: 'Dashboard',
          link: '/dashboard/analytics'
        }
      ]
    },
    {
      text: 'Servicios',
      items: [
        { icon: 'mdi-bullhorn', text: 'Servicios', link: '/services' },
        {
          icon: 'mdi-chart-line',
          text: 'Reportería',
          items: [
            { text: 'Campañas', link: '/reports/campaigns' },
            { text: 'Registros', link: '/reports/registers' }
          ]
        }
      ]
    },{
      text: 'Atención al cliente',
      key: '',
      items: [
        {
          icon: 'mdi-headset',
          text: 'Atención al cliente',
          items: [
            { text: 'Configuración', link: '/management/workgroups' },
            { text: 'Supervisar', link: '/management/supervisor' },
            { text: 'Hipervision', link: '/management/hipervisor' }
            //{ text: 'Chat', link: '/tools/chat' }
          ]
        }
      ]
    },
    {
      text: 'Herramientas',
      key: '',
      items: [
        { icon: 'mdi-file-image', text: 'Archivos', link: '/tools/filescloud' },
        { icon: 'mdi-link-plus', text: 'URL', link: '/tools/shorturl' },
        { icon: 'mdi-contacts', text: 'Agendas', link: '/tools/agendas' }
      ]
    },
    {
      text: 'Chat',
      key: '',
      items: [
        //{ icon: 'mdi-message', text: 'Chat', link: '/tools/chat' }
        //Nueva Ruta
        { icon: 'mdi-message', text: 'Chat', link: '/agent/tools/chat' }
      ]
    },
    {
      text: 'Configuración',
      key: '',
      items: [
        //{ icon: 'mdi-account',  text: 'Mi usuario', link: '/config/user' },
        {
          icon: 'mdi-account-group',
          text: 'Usuarios',
          link: '/config/users'
        }
      ]
    },
    {
      text: 'Canales',
      key: '',
      items: [
        {
          icon: 'mdi-call-split',
          text: 'Proveedores',
          link: '/channels/vendors'
        }
      ]
    },
    {
      text: 'Documentación',
      items: [
        {
          icon: 'mdi-file-document',
          text: 'Documentación Api',
          link: 'http://enviamas-documentation-s3.s3-website-us-east-1.amazonaws.com/guide/authentication.html'
        }
      ]
    },
    {
      text: 'Otros',
      key: '',
      items: [
        {
          icon: 'mdi-call-split',
          text: 'Bots',
          link: '/bots'
        },
        {
          icon: 'mdi-call-split',
          text: 'Compañias',
          link: '/companies'
        }
      ]
    }
  ]
}
