export class ValidationError extends Error {
  payload;
  constructor(message, details, displayedMessage) {
    super(message)
    this.name = 'ValidationError'
    this.details = details 
    this.displayedMessage = displayedMessage
  }
}

export class RequestError extends Error {
  payload;
  constructor(message, payload) {
    super(message)
    this.name = 'RequestError'
    this.payload = payload 
  }
}
export class SearchError extends Error {
  payload;
  constructor(message, payload) {
    super(message)
    this.name = 'SearchError'
    this.payload = payload 
  }
}