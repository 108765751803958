import store from '../../store'
import BackendApi from '@/services/backend.service'
import { ValidationError } from '../../services/errorHandler.service'

//falta declarar este modulo????
const state = {
  //Padre: ChatApp
  type_of_entry : '',//'supervisor o agente
  agent_info: {
    name: '',
    agent_id: '',
    workgroup_id: '',
    status: null,
    user_id: null,
    email: ''
  },
  company_info: {
    id: null,
    phone: '',
    name: ''
  },

  //Menú de Conversaciones: ChatClientDirectory
  clients_directory: [],
  menu_expanded_stage_index: null,
  is_searching_conversations: false,

  //Chat: ChannelPage
  chat_info: {
    client: {
      name: '',
      phone: ''
    },
    expirated_at: '',
    last_message: {
      content: {},
      created_at: '',
      type: ''
    },
    last_ticket: {
      fixed: null,
      id: null,
      status_id: ''
    },
    stage_name: '',
    unread_message_count: 0
  },

  //state sobre Responder mensaje
  send_message_input_info: {
    is_responding: false,
    id: null,//agregar todavia no se lo tiene
    message_responded: null
  },

  default_search_messages: {
    is_open: false,
    input_value: ''
  },
    
  //Variables Auxiliares
  chat_info_is_aviable: false,

  //Multimedia preview Channel Page
  multimedia_preview: {
    is_open: false,
    is_loading: false,
    uploaded_file: {
      'type': '',
      'name': '',
      'content': null, 
      'file_long_url': null,
      'file_thumbnail': null
    },
    caption_text: ''
  },

  is_loading_messages: false,
  remaining_chat_time: -1,
  is_need_reactivate: true,
  messages: []
}

const getters = {
}
const mutations = {
  UPDATE_CHAT_CLIENT_ALIAS(state, payload) {
    state.chat.client.alias = payload
  },
  UPDATE_MESSAGE_IDS(state, { index, id, message_id }) {
    state.messages[index].id = id
    state.messages[index].message_id = message_id
  },
  UPDATE_MESSAGE_REACTION(state, { index, reaction }) {
    state.messages[index].reaction = reaction
  },
  UPDATE_MESSAGE_STATUS(state, { index, status }) {
    state.messages[index].status = status
  },
  ADD_MESSAGE(state, payload) {
    state.messages.push(payload)
  },
  ADD_CHAT_VALIDATE_MESSAGE(state, { message, client_phone }) {
    if (parseInt(client_phone) === parseInt(state.chat_info.client.phone)) {
      state.messages.push(message)
    } else {
      console.error('El chat no es el mismo', { message, client_phone, chat_info: state.chat_info.client })
    }
  },
  SET_MESSAGES(state, payload) {
    state.messages = payload
  },
  SET_IS_NEED_REACTIVATE(state, payload) {
    state.is_need_reactivate = payload
  },
  SET_IS_LOADING_MESSAGES(state, payload) {
    state.is_loading_messages = payload
  },
  SET_REMAINING_CHAT_TIME(state, payload) {
    state.remaining_chat_time = payload
  },
  SetDefaultSearchMessages(state, payload) {
    const { default_search_messages } = payload

    state.default_search_messages = default_search_messages
  },
  setSendMessageInputInfo(state, payload) {
    const { send_message_input_info } = payload

    state.send_message_input_info = send_message_input_info
  },
  setChatInfo(state, payload) {
    const { conversation, stage_name } = payload

    //Si no mantengo la cantidad de propieades vue no detecta mi cambio de fix a no fix
    const last_ticket = {
      id: conversation.last_ticket.id,
      fixed: conversation.last_ticket.fixed,
      status_id: conversation.last_ticket.status_id
    }

    state.chat_info = conversation
    state.chat_info.last_ticket = last_ticket

    //state.chat_info.stage_name = stage_name
      
    state.chat_info_is_aviable = true

  },
  setTypeOfEntry(state, payload) {      
    state.type_of_entry = payload.type_of_entry
  },
  addMoreConversationsByStage(state, payload) {
    const { stage_id, new_conversations, stage_type, add_type } = payload
    const stage_index = stage_id - 1

    //Para cuando no se ha brindado un conversation_type(fixed, no fixed)
    if (!stage_type) {
      if (add_type === 'push') {
        state.clients_directory[stage_index].conversations.push(...new_conversations)
      } else if ( add_type === 'unshift') {
        state.clients_directory[stage_index].conversations.push(...new_conversations)
      } else {
        console.error('add_type not valid', add_type)
      }
    } else {
      state.clients_directory[stage_index].conversations[stage_type].push(...new_conversations)
    }
  },
  setClientsDirectory(state, payload) {

    state.clients_directory = []
    state.clients_directory = payload.new_value
  },
  setAgentInfo(state, payload) {
    state.agent_info = payload.agent_info
    state.agent_data_is_aviable = true
  },
  setCompanyInfo(state, payload) {
    state.company_info = payload.company_info
  },
  setAgentInfoStatus(state, payload) {
    const { new_status } = payload

    state.agent_info.status = new_status
  },
  setMenuExpandedStageIndex(state, payload) {
    state.menu_expanded_stage_index = payload.new_value
  },
  setIsSearchingConversations(state, payload) {
    state.is_searching_conversations = payload.is_searching
  },
  setUnreadMessageCount(state, payload ) {
    const { stage_index, conversation_index, stage_type, operation } = payload
    let conversations

    //operations:
    //+1:suma, -1: resta, 0: reestablecer
    switch (stage_index) {
    case 0://Pendientes 
    case 2://Exitosos 
    case 3://Fallidos 
      conversations = state.clients_directory[stage_index].conversations[conversation_index] 

      if (operation === 0) {
        conversations ? null : console.error('Error setUnreadMessageCount, no se ha encontrado la conversacion', payload) 

        conversations.unread_message_count = 0 
      } else {
        conversations ? null : console.error('Error setUnreadMessageCount, no se ha encontrado la conversacion', payload) 

        conversations.unread_message_count += operation 
      }
      break
    case 1://En atención 
      conversations = state.clients_directory[1].conversations[stage_type][conversation_index]
      if (operation === 0) {
        conversations ? null : console.error('Error setUnreadMessageCount, no se ha encontrado la conversacion', payload)  

        conversations.unread_message_count = 0
      } else {
        conversations ? null : console.error('Error setUnreadMessageCount, no se ha encontrado la conversacion', payload)  

        conversations.unread_message_count += operation
      }
      break
    default:
      console.error('(setUnreadMessageCount) No se ha ingresado un nombre del stage válido')
      break
    }
  },    
  setDrawerClientAlias(state, payload ) {
    const { stage_index, conversation_index, stage_type, new_alias, new_alias_id } = payload
    let conversations

    //operations:
    //+1:suma, -1: resta, 0: reestablecer
    switch (stage_index) {
    case 0://Pendientes 
    case 2://Exitosos 
    case 3://Fallidos 
      conversations = state.clients_directory[stage_index].conversations[conversation_index] 

      if (!conversations) throw new Error('Error setDrawerClientAlias, no se ha encontrado la conversacion', payload) 

      conversations.client.alias = new_alias 
      conversations.client.alias_id = new_alias_id 
      
      break
    case 1://En atención 
      conversations = state.clients_directory[1].conversations[stage_type][conversation_index]
      
      if (!conversations) throw new Error('Error setDrawerClientAlias, no se ha encontrado la conversacion', payload) 

      conversations.client.alias = new_alias 
      conversations.client.alias_id = new_alias_id 
      
      break
    default:
      throw new ValidationError('No se ha proporcionado el stage_index válido', { payload })
    }
  },    
  removeConversationOfMenu(state, payload) {
    const { stage_index, conversation_index, stage_type } = payload
    let conversations

    switch (stage_index) {
    case 0: //Pendientes
    case 2: //Exitosos
    case 3: //Fallidos
      conversations = state.clients_directory[stage_index].conversations
      conversations ? null : console.error('No se ha encontrado una conversación para remover', payload) 
              
      conversations.splice(conversation_index, 1)[0]
        
      state.clients_directory[stage_index].conversations_count--
      break
    case 1: //En Atención
      conversations = state.clients_directory[stage_index].conversations[stage_type]
      conversations ? null : console.error('No se ha encontrado una conversación para remover', payload) 

      conversations.splice(conversation_index, 1)[0]
      state.clients_directory[stage_index].conversations_count--
      //Para que no se reduzca el contador cuando se fija o se desfija, cambiar esto
      /* if (stage_type !== 'no_fixed') {
          state.clients_directory[stage_index].conversations_count--
        } */
      break
    default: 
      console.error('No se ha ingreesado un stage_index válido, stage_index: ', stage_index)
      break
    }
  },
  putConversationInMenu(state, payload) {
    const { stage_index, stage_type, conversation, put_type } = payload
      
    if ( stage_type !== 'fixed' && stage_type !== 'no_fixed' ) {
      console.error('No se ha ingresado un stage_type correcto (permitidos: all, fixed, no_fixed). Search type actual: ', stage_type)
    }      

    let conversations
    let fixed_boolean_value

    switch (stage_index) {
    case 0: //Pendientes
    case 2: //Exitosos
    case 3: //Fallidos
      conversations = state.clients_directory[stage_index].conversations
      conversations ? null : console.error('No se ha encontrado una conversación para remover', payload) 
      conversation.last_ticket.fixed = 0       
      conversations.unshift(conversation)
      state.clients_directory[stage_index].conversations_count++
      //state.chat_info.last_ticket.status_id = (stage_index + 1).toString() // Vista de Botones 
      //state.menu_expanded_stage_index = null // Menú Cerrado
      break
    case 1: //En Atención
      fixed_boolean_value = stage_type === 'fixed' ? 1 : 0

      conversation.last_ticket.fixed = fixed_boolean_value
      conversations = state.clients_directory[stage_index].conversations[stage_type]
      conversations ? null : console.error('No se ha encontrado una conversación para remover', payload) 

      conversations.unshift(conversation)
      
      state.chat_info.last_ticket.status_id = '2' 
      //Para que no se sume el contador cuando se esta fijando o desfijando, cambiar
      state.clients_directory[stage_index].conversations_count++
      if (put_type !== 'fix') {
        state.chat_info.last_ticket.status_id = (stage_index + 1).toString() // Vista de Botones 
      }
      state.menu_expanded_stage_index = 1 // Menú abierto
      break
    default: 
      console.error('No se ha ingreesado un stage_index válido, stage_index: ', stage_index)
      break
    }
  },

  //Multimedia Preview
  setMultimediaPreview(state, payload) {
    const { multimedia_preview } = payload

    state.multimedia_preview = multimedia_preview

  }
}
const actions = {
  async updateClientAlias({ commit, dispatch }, data) {
    const { new_alias, new_alias_id, client_phone } = data

    try {
      if (state.chat_info.client_phone === client_phone) {
        commit('UPDATE_CHAT_CLIENT_ALIAS', new_alias)
      }
      
      const found_ticket = await dispatch('findConversationInMenu', {
        stages_id: [1, 2, 3, 4],
        search_type: 'all',
        search_criteria: client_phone,
        find_by: 'client_phone'
      })

      if (found_ticket === -1) throw new ValidationError('No se ha encontrado el ticket para actualizar el alias del ticket', { found_ticket, client_phone })
      
      commit({ 
        type: 'setDrawerClientAlias', 
        stage_index: found_ticket.stage_index, 
        conversation_index: found_ticket.index, 
        stage_type: found_ticket.type,
        new_alias,
        new_alias_id
      })
      
    } catch (error) {
      if (error instanceof ValidationError) {
        console.error(error.message, error.details)
      } else {
        console.error(error, { data })
      }
    }
  },
  findConversationInMenu({ state, dispatch }, payload) {
    let conversations_routes 
    const { stages_id, search_type, search_criteria, find_by  } = payload

    //stages_id es un array [1,2,3,4]

    //Manejo de errores
    if ( search_type !== 'all' && search_type !== 'fixed' && search_type !== 'no_fixed' ) {
      console.error('No se ha ingresado un search_type correcto (permitidos: all, fixed, no_fixed). Search type actual: ', search_type)
    }

    if ( find_by !== 'client_phone' && find_by !== 'ticket_id' && find_by !== undefined ) {//undefined debería ser ticket id
      console.error('No se ha ingresado un find_by correcto (permitidos: client_phone, ticket_id). Search type actual: ', find_by)
    }
    //Fin de manejo de errores

    return dispatch('setConversationsRoutes', { stages_id, search_type })
      .then((response) => {
        conversations_routes = response 

        for (const route of conversations_routes) {
          const stage = state.clients_directory[route.stage_index]
          const conversations = route.type ? stage.conversations[route.type] : stage.conversations 

          let index

          //Hacer esto mejor luego y crear el tipo find_by ticket_id en lugar de que este en el else
          if ( find_by === 'client_phone' ) {
            index = conversations.findIndex((item) => Number(item.client.phone) === Number(search_criteria))

          } else {
            //No se utiliza actutalmente
            index = conversations.findIndex((item) => item.last_ticket.id === search_criteria)
          }
    
          if (index !== -1) {
            const found_conversation = {
              stage_index: route.stage_index,
              index: index,
              type: route.type,
              conversation: conversations[index]
            }

            return found_conversation
          }
        }
      
        return -1 // Devuelve -1 si no se encuentra la conversación
      })
  },    
  setConversationsRoutes({ state }, payload) {
    const { stages_id, search_type } = payload
        
    const searchRoutes = [] //1 a 5
    
    stages_id.forEach((stage_id) => {
      switch (stage_id) {
      case 1:
        searchRoutes.push({ stage_index: 0 })

        break
      case 2:
        if (search_type === 'all') {
          searchRoutes.push({ stage_index: 1, type: 'fixed' })
          searchRoutes.push({ stage_index: 1, type: 'no_fixed' })
        } else {
          searchRoutes.push({ stage_index: 1, type: search_type })
        }
        break
      case 3:
        searchRoutes.push({ stage_index: 2 })
        break
      case 4:
        searchRoutes.push({ stage_index: 3 })
        break
      default:
        console.error('Opción no válida para el stage:', stage)
      }
    })
    
    return searchRoutes
  },
  IsSearchingConversations ({ commit }, data) {
    commit('setIsSearchingConversations', data)
  },
  chatInfo ({ commit }, data) {
    commit('setChatInfo', data)
  },
  navDrawerInfo ({ commit, dispatch }, data) {
        
    commit({ type: 'setCompanyInfo', company_info: data.company_info })      
    commit({ type: 'setAgentInfo', agent_info: data.agent_info })
  },
  async addNewTicket({ state, commit, dispatch }, new_ticket) {
    console.log('llamado a new ticket')
    try {
      if (!new_ticket) throw new Error('No se ha proporcionado el nuevo ticket')
        
      const found_ticket = await dispatch('findConversationInMenu', {
        stages_id: [1, 2, 3, 4],
        search_type: 'all',
        search_criteria: new_ticket.client.phone,
        find_by: 'client_phone'
      })

      console.log({ found_ticket })
      if (found_ticket && found_ticket.conversation && found_ticket.conversation.last_ticket) {
        const found_status_id = parseInt(found_ticket.conversation.last_ticket.status_id)
        
        if (found_status_id === 1 || found_status_id === 2) {
  
          return -1
        }
      }

      commit({ 
        type: 'removeConversationOfMenu', 
        stage_index: found_ticket.stage_index, 
        conversation_index: found_ticket.index, 
        stage_type: found_ticket.type 
      })
        
      // Formatear Ticket
      new_ticket.last_ticket.status_id = 1 
      new_ticket.last_ticket.fixed = 0 
      new_ticket.unread_message_count = 1
        
      commit({ type: 'putConversationInMenu', stage_type: 'no_fixed', stage_index: 0, conversation: new_ticket })

      return new_ticket 
    } catch (error) {
      console.error(error, { new_ticket })

      return -1
    }
  },
  async updateLastMessage({ state, commit, dispatch }, new_message) {
    try {
      if (!new_message) throw new Error('No se ha proporcionado el nuevo mensaje')

      const found_ticket = await dispatch('findConversationInMenu', {
        stages_id: [1, 2, 3, 4],
        search_type: 'all',
        search_criteria: new_message.client.phone,
        find_by: 'client_phone'
      })
        
      if (found_ticket === -1) throw new Error('No se ha encontrado el ticket necesario')
      if (Number(found_ticket.conversation.last_message.id) === Number(new_message.message.id)) throw new Error('Este mensaje ya ha sido recibido')
      const new_last_message_formatted = {
        id: new_message.message.id,
        content: new_message.message.content,
        created_at: new_message.message.created_at,
        status: new_message.message.status,
        type: new_message.message.type
      }

      await dispatch('updateLastMessageInConversation',
        {
          stage_index: found_ticket.stage_index,
          conversation_index: found_ticket.index,
          new_message: new_last_message_formatted,
          stage_type: found_ticket.type,
          inbound_client_phone: new_message.client.phone
        })
    } catch (error) {
      console.error(error, { new_message })
    }
  },
  async updateLastMessageInConversation({ state }, payload) {
    const { stage_index, conversation_index, new_message, stage_type } = payload

    const conversations = state.clients_directory[stage_index].conversations

    let removed_conversation 

    switch (stage_index) {
    case 0://Pendientes
    case 2://Exitosos
    case 3://Fallidos
      if (new_message.type === 'inbound') {
        conversations[conversation_index].unread_message_count++
      }
      conversations[conversation_index].last_message = new_message

      //Para actualizar el contador de no leídos si no estas actualmente en el mismo chat del que llegó
      //Para agregar esto se tendría que quitar el chat info cuando se cierra
      //if ( String(state.chat_info.client.phone) !== String(inbound_client_phone)) {
      //}

      //Para colocar la conversación al inicio de todo
      removed_conversation = conversations.splice(conversation_index, 1)
        
      conversations.unshift(removed_conversation[0])

      break
    case 1: //En Atención
      if (new_message.type === 'inbound') {
        conversations[stage_type][conversation_index].unread_message_count++
      }
      conversations[stage_type][conversation_index].last_message = new_message

      //Para actualizar el contador de no leídos si no estas actualmente en el mismo chat del que llegó
      //if ( String(state.chat_info.client.phone) !== String(inbound_client_phone)) {
      //}

      //Para colocar la conversación al inicio de todo
      removed_conversation = conversations[stage_type].splice(conversation_index, 1)

      conversations[stage_type].unshift(removed_conversation[0])

      break
    default:
      break
    }
  },
  async getMessages({ state, commit }) {
    commit('SET_MESSAGES', [])
    commit('SET_IS_LOADING_MESSAGES', true)
    const payload = {
      agent_id: state.agent_info.agent_id,
      company_phone: state.company_info.phone,
      client_phone: state.chat_info.client.phone
    }
    
    try {
      const response = await BackendApi.post('/chat/get_conversation_messages', payload)
      const conversation_info = response.data.data.conversation
        
      commit('SET_REMAINING_CHAT_TIME', conversation_info.seconds_to_expirate)
      commit('SET_IS_NEED_REACTIVATE', conversation_info.is_need_reactivate)
      commit('SET_MESSAGES', response.data.data.messages)

      commit('SET_IS_LOADING_MESSAGES', false)    

    } catch (error) {
      commit('SET_IS_LOADING_MESSAGES', false)    
      if (error instanceof ValidationError) {
        console.error(error.message, error.details)
      } else {
        console.error(error)
      }
    }
  },
  async sendMessage ({ state, commit }, data) {
    const { content, agent_info, ticket_id, company_info, client } = data
    const { type, payload } = content
    const local_message_id = '_' + Math.random().toString(36).substr(2, 9)

    try {
  
      if (!content || !type || !payload || !agent_info || !ticket_id || !company_info || !client) {
        throw new ValidationError('Faltan parámetros', { type, payload, content, ticket_id  })
      }
      validateContentByType(type, payload)
      
      /////////////////////
      let message_context = null

      if (state.send_message_input_info.message_responded) {
        message_context =  state.send_message_input_info.message_responded

        if (!message_context || !message_context.content || !message_context.content.payload) {
          throw new ValidationError('MessageContentx incorrecto', { message_context })
        }

        message_context.content.payload.context = null
      }

      let formatted_context = null

      if (message_context) {
        formatted_context = {
          'id': message_context.id,
          'created_at': message_context.created_at,
          'sender': {
            'name': ''
          },
          'content': message_context.content
        }
      }
      content.payload.context = formatted_context

      const is_first_of_type = verifyIfIsFirst(state.messages)
      const currentDate = new Date()

      currentDate.setHours(currentDate.getHours() - 5) 
      const current_date_formatted = currentDate.toISOString().substring(0, 19).replace('T', ' ')

      const agent_message = {
        id: local_message_id,
        message_id: null,
        content: content,
        status:'QUEUED',  
        created_at: current_date_formatted,
        type: 'outbound',
        isFirstOfType: is_first_of_type,
        reaction: null,
        owner: {
          name: state.agent_info.name
        }
      }
      ///////////////////////

      commit('ADD_MESSAGE', agent_message)
      
      let context_id = null

      if (message_context) {
        context_id = message_context.message_id
      }

      const apiPayload = getApiPayload({ agent_info, ticket_id, company_info, client, context_id, content })
      
      const response = await BackendApi.post('/chat/send_message', apiPayload)
  
      if (!response || !response.data || !response.data.data) {
        throw new ValidationError('No se pudo obtener la respuesta del backend', response)
      }

      const message_sent = response.data.data

      const messageIndex = state.messages.findIndex((message) => message.id === local_message_id)

      if (messageIndex !== -1) {

        commit('UPDATE_MESSAGE_IDS', { index: messageIndex, id: message_sent.id, message_id: message_sent.message_id })
      }
  
    } catch (error) {
      const messageIndex = state.messages.findIndex((message) => message.id === local_message_id)

      if (messageIndex !== -1 ) commit('UPDATE_MESSAGE_STATUS', { index: messageIndex, status: 'FAILED' })

      if (error instanceof ValidationError) {
        console.error(error.message, error.details)
      } else {
        console.error(error, { content, agent_info, ticket_id, company_info, client })
      }
    }
  }
}

function verifyIfIsFirst(messages) {
  const last_index = messages.length - 1
  const last_message = messages[last_index]

  return last_message.type === 'outbound' ? false : true
}

function validateContentByType(type, payload) {
  const errMessage = 'payload incorrecto o faltante'

  if (type === 'text') {
    if (!payload?.text.trim()) throw new ValidationError (errMessage, { payload, type })

  } else if (type === 'image' || type === 'video' || type === 'file' || type === 'audio') {
    if (!payload.url) throw new ValidationError(errMessage, { payload, type })

  } else {
    throw new ValidationError('No se ha encontrado un type correcto', { payload, type })
  }
}

function getApiPayload({ agent_info, ticket_id, company_info, client, context_id, content }) {
  const apiPayload = {
    ticket_id,
    agent_info,
    company_info,
    client,
    message: {
      context_id,
      content: {
        type: content.type,
        ...content.payload
      }
    }
  }

  return apiPayload
}

export default {
  state,
  getters,
  mutations,
  actions
}