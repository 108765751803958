import Vue from 'vue'
import Vuex from 'vuex'

// Global vuex
import AppModule from './app'

//Authenticate
import Authenticate from './authenticate/'
import Users from './users/'
import Credits from './credits/'
import Campaings from './campaings/'
import Sms from './sms'
import sockets from './sockets'
import whatsapp_Api from './whatsapp-api'
import Chat from './chat'
import customizationMenu from './customizationMenu'
Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    app: AppModule,
    authenticate: Authenticate,
    users: Users,
    campaings: Campaings,
    credits: Credits,
    sms: Sms,
    sockets,
    whatsapp_Api,
    Chat,
    customizationMenu
  }
})

export default store
