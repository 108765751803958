import BackendApi from '@/services/backend.service'

export default [
  //SERVICES
  {
    path: '/services',
    name: 'services',
    component: () => import('@/pages/Services/services.vue')
  },
  //SMS
  {
    path: '/services/sms/create_campaing',
    name: 'create-campaing-sms',
    component: () => import('@/pages/Services/sms/CreateCampaing.vue')
  },
  {
    path: '/services/sms/create_campaing/sms_individual',
    name: 'sms-individual',
    component: () => import('@/pages/Services/sms/SmsIndividual.vue')
  },
  {
    path: '/services/sms/create_campaing/sms_agendas',
    name: 'sms-agendas',
    component: () => import('@/pages/Services/sms/SmsAgenda/SmsAgendaStepOne.vue')
  },
  {
    path: '/services/sms/create_campaing/sms_agenda_step_two',
    name: 'sms-agenda-step-two',
    component: () => import('@/pages/Services/sms/SmsAgenda/SmsAgendaStepTwo.vue')
  },
  // {
  //   path: '/services/sms/create_campaing/sms_agendas',
  //   name: 'sms-agendas',
  //   component: () => import('@/pages/Services/sms/SmsAgendas.vue')
  // },
  {
    path: '/services/sms/create_campaing/sms_excel',
    name: 'sms-excel',
    component: () => import('@/pages/Services/sms/SmsExcel/SmsExcelStepOne.vue')
  },
  {
    path: '/services/sms/create_campaing/sms_excel_step_two',
    name: 'sms-excel-step-two',
    component: () => import('@/pages/Services/sms/SmsExcel/SmsExcelStepTwo.vue')
  },
  {
    path: '/services/sms/create_campaing/sms_scheduled',
    name: 'sms-calendarizado',
    component: () => import('@/pages/Services/sms/SmsScheduled/SmsScheduledStepOne.vue')
  },
  {
    path: '/services/sms/create_campaing/sms_scheduled_two',
    name: 'sms-excel-schedule-two',
    component: () => import('@/pages/Services/sms/SmsScheduled/SmsScheduledStepTwo.vue')
  },
  // WhatsApp
  {
    path: '/services/whatsapp/api_dashboard/',
    name: 'whatsapp-api-dasboard',
    component: () => import('@/pages/Services/whatsapp/ApiDashboard.vue')
  },
  //whatsApp-template
  {
    path: '/services/whatsapp/api_dashboard/template_list',
    name: 'whatsapp-template-list',
    component: () => import('@/pages/Services/whatsapp/TemplateList.vue')
  },
  //whatsApp-campaing
  //whatsApp-campaing-individual
  {
    path: '/services/whatsapp/api_dashboard/individual_campaing',
    name: 'whatsapp-individual-campaign',
    component: () => import('@/pages/Services/whatsapp/IndividualCampaing.vue')
  },
  //whatsApp-campaing-agenda
  {
    path: '/services/whatsapp/api_dashboard/whatsapp_agendas',
    name: 'whatsapp-agendas',
    component: () => import('@/pages/Services/whatsapp/WhatsappAgenda/WhatsappAgendaStepOne.vue')
  },
  {
    path: '/services/whatsapp/api_dashboard/whatsapp_agenda_step_two',
    name: 'whatsapp-agenda-step-two',
    component: () => import('@/pages/Services/whatsapp/WhatsappAgenda/WhatsappAgendaStepTwo.vue')
  },
  //whatsApp-campaing-excel
  {
    path: '/services/whatsapp/api_dashboard/whatsapp_excel',
    name: 'whatsapp-excel',
    component: () => import('@/pages/Services/whatsapp/WhatsappExcel/WhatsappExcelStepOne.vue')
  },
  {
    path: '/services/whatsapp/api_dashboard/whatsapp_excel_step_two',
    name: 'whatsapp-excel-step-two',
    component: () => import('@/pages/Services/whatsapp/WhatsappExcel/WhatsappExcelStepTwo.vue')
  },
  // Supervisor Dashboard
  {
    path: '/management/supervisor',
    name: 'supervisor-dashboard',
    component: () => import('@/pages/Chat/supervisor/SupervisorDashboard.vue')
  },

  // WORKGROUPS
  {
    path: '/management/workgroups',
    name: 'workgroups',
    beforeEnter: (to, from, next) => {
      BackendApi.get('/chat/chat_user_state')
        .then((response) => {
          if (response.data.data.isChatOwner) {
            return next()
          }

          return next('/error')
        })
        .catch((error) => {
          next('/error')
        })
    },
    component: () => import('@/pages/Workgroups/Dashboard.vue')

  },
  {
    path: '/management/agent',
    name: 'chat-supervised-agent',//chat-supervised-agent
    component: () => import('@/pages/Chat/ChatApp.vue'),
    beforeEnter: (to, from, next) => {
      BackendApi.get('/chat/chat_user_state')
        .then((response) => {
          if (response.data.data.isChatSupervisor) {

            return next()
          }//$route.params.mvariable != undefined ()´
          //this.routePush =>  supervisor-dashboard

          return next('/error')
        })
        .catch((error) => {
          next('/error')
        })
    }, 
    children:[
      { 
        path: '/:workgroup_id/:agent_name/:current_chat_ticekts_agent_id/chat',
        name:'management-supervisor-chat',
        components:{ 
          default: () => import('@/pages/Chat/InitChat.vue'),
          drawer: () => import('@/pages/Chat/components/NavDrawerComponent.vue') 
        }
        
      },
      {
        path: ':agent_supervised_id/chat/:supervisor_ticket_id',
        name:'management-supervisor-chat-id',
        components:{ 
          default:() => import('@/pages/Chat/pages/ChannelPage.vue'),
          drawer: () => import('@/pages/Chat/components/NavDrawerComponent.vue') 
        }
        
      }
    ]

  },

  //REPORTS
  {
    path: '/reports/campaigns',
    name: 'reports',
    component: () => import('@/pages/Reports/Reports.vue')

  },
  {
    path: '/reports/registers',
    name: 'registers',
    component: () => import('@/pages/Reports/Registers.vue')
  },
  {
    path: '/reports/sms/detail/:campaign_id',
    name: 'reports-sms',
    component: () => import('@/pages/Reports/DetailSmsReport.vue')
  },
  {
    path: '/reports/email/detail/:campaign_id',
    name: 'reports-email',
    component: () => import('@/pages/Reports/DetailEmailReport.vue')
  },
  {
    path: '/reports/whatsapp/detail/:campaign_id',
    name: 'reports-whatsapp',
    component: () => import('@/pages/Reports/DetailWhatsappReport.vue')
  },
  //IVR
  {
    path: '/ivr/create_campaing',
    name: 'create-campaing-ivr',
    component: () => import('@/pages/Services/ivr/CreateCampaing.vue')
  },
  {
    path: '/ivr/create_campaing/ivr_individual',
    name: 'ivr-individual',
    component: () => import('@/pages/Services/ivr/IvrIndividual.vue')
  },
  {
    path: '/ivr/create_campaing/ivr_agendas',
    name: 'ivr-agendas',
    component: () => import('@/pages/Services/ivr/IvrAgendas.vue')
  },
  {
    path: '/ivr/create_campaing/ivr_excel',
    name: 'ivr-excel',
    component: () => import('@/pages/Services/ivr/IvrExcel.vue')
  },
  {
    path: '/mailing/create_mailing/mailing',
    name: 'mailing-create',
    component: () => import('@/pages/Services/mailing/mailingForm.vue')
  },
  {
    path: '/mailing/create_mailing/mailingHome/:campaigns/:mailingCampaigns',
    name: 'mailing-home',
    beforeEnter: (to, from, next) => {
      BackendApi.get('/chat/chat_user_state')
        .then((response) => {
          next()
        })
        .catch((error) => {
          next('/error')
        })
    },
    component: () => import('@/pages/Services/mailing/mailingHome.vue')
  },
  {
    path: '/mailing/create_mailing/mailingAgenda',
    name: 'mailing-create-agenda',
    component: () => import('@/pages/Services/mailing/mailingFormAgenda.vue')
  },
  {
    path: '/mailing/create_mailing/mailingHomeAgenda/:campaigns/:mailingCampaigns',
    name: 'mailing-home-agenda',
    beforeEnter: (to, from, next) => {
      BackendApi.post('/validateUrl', {
        campaigns: to.params.campaigns,
        mailingCampaigns: to.params.mailingCampaigns
      })
        .then((response) => {
          next()
        })
        .catch((error) => {
          next('/error')
        })
    },
    component: () => import('@/pages/Services/mailing/mailingHomeAgenda.vue')
  },
  {
    path: '/mailing/create_mailing/mailingExcel',
    name: 'mailing-create-excel',
    component: () => import('@/pages/Services/mailing/mailingFormExcel.vue')
  },
  {
    path: '/mailing/create_mailing/mailingHomeExcel/:campaigns/:mailingCampaigns',
    name: 'mailing-home-excel',
    beforeEnter: (to, from, next) => {
      BackendApi.post('/validateUrl', {
        campaigns: to.params.campaigns,
        mailingCampaigns: to.params.mailingCampaigns
      })
        .then((response) => {
          next()
        })
        .catch((error) => {
          next('/error')
        })
    },
    component: () => import('@/pages/Services/mailing/mailingHomeExcel.vue')
  }
]
